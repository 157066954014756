import { AppPropsWithLayout } from '~/types/layout';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import Head from 'next/head';
import { ThemeProvider } from '@mui/system';
import { theme } from '~/utils/theme';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';
import { withUrqlClient } from '~/utils/urql';
import { AuthProvider } from '~/context/Auth';
import dayjsJA from 'dayjs/locale/ja';

const App = ({ Component, pageProps }: AppPropsWithLayout): JSX.Element => {
  const getLayout = Component.getLayout ?? ((page) => page);
  LicenseInfo.setLicenseKey(
    '7bfdb2a7e01d85fe9e46689356ca6b9bT1JERVI6MzE1OTQsRVhQSVJZPTE2NjY5NTA0ODIwMDAsS0VZVkVSU0lPTj0x',
  );
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>kokuyo-locker</title>
      </Head>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <LocalizationProvider
            adapterLocale={dayjsJA}
            dateAdapter={AdapterDayjs}
          >
            <CssBaseline />
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              {getLayout(<Component {...pageProps} />)}
            </SnackbarProvider>
          </LocalizationProvider>
        </AuthProvider>
      </ThemeProvider>
    </>
  );
};

export default withUrqlClient(App);
