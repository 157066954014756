import { createTheme } from '@mui/material';
import { jaJP as muiJaJp } from '@mui/material/locale';
import { jaJP as dataGridJaJp } from '@mui/x-data-grid-pro';

export const theme = createTheme(
  {
    typography: {
      h1: {
        fontWeight: 'bold',
      },
      h2: {
        fontWeight: 'bold',
      },
      h3: {
        fontWeight: 'bold',
      },
      h4: {
        fontWeight: 'bold',
      },
      h5: {
        fontWeight: 'bold',
      },
      h6: {
        fontWeight: 'bold',
      },
    },
    palette: {
      primary: {
        main: '#012E7B',
        contrastText: '#fff',
      },
      background: {
        default: '#F5F5F5',
      },
    },
  },
  muiJaJp,
  dataGridJaJp,
);
