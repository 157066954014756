import type { OptionalQuery as OptionalQuery0 } from '../pages/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/groups'
import type { OptionalQuery as OptionalQuery1 } from '../pages/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/groups/[groupId]/lockers/[lockerId]'
import type { OptionalQuery as OptionalQuery2 } from '../pages/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/rental'

export const pagesPath = {
  "$7ac6dd9d_43f8_4f2b_b632_9d18c6fe239f": {
    "admin": {
      "lockers": {
        _lockerId: (lockerId: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/admin/lockers/[lockerId]' as const, query: { lockerId }, hash: url?.hash })
        }),
        $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/admin/lockers' as const, hash: url?.hash })
      },
      "logs": {
        $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/admin/logs' as const, hash: url?.hash })
      },
      "reservations": {
        $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/admin/reservations' as const, hash: url?.hash })
      },
      "users": {
        "new": {
          $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/admin/users/new' as const, hash: url?.hash })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/admin/users' as const, hash: url?.hash })
      }
    },
    "error": {
      $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/error' as const, hash: url?.hash })
    },
    "groups": {
      _groupId: (groupId: string | number) => ({
        "lockers": {
          _lockerId: (lockerId: string | number) => ({
            "members": {
              $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/groups/[groupId]/lockers/[lockerId]/members' as const, query: { groupId, lockerId }, hash: url?.hash })
            },
            "reservation": {
              $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/groups/[groupId]/lockers/[lockerId]/reservation' as const, query: { groupId, lockerId }, hash: url?.hash })
            },
            $url: (url?: { query?: OptionalQuery1, hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/groups/[groupId]/lockers/[lockerId]' as const, query: { groupId, lockerId, ...url?.query }, hash: url?.hash })
          })
        }
      }),
      $url: (url?: { query?: OptionalQuery0, hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/groups' as const, query: url?.query, hash: url?.hash })
    },
    "login": {
      $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/login' as const, hash: url?.hash })
    },
    "notifications": {
      $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/notifications' as const, hash: url?.hash })
    },
    "qr": {
      _lockerId: (lockerId: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/qr/[lockerId]' as const, query: { lockerId }, hash: url?.hash })
      })
    },
    "qr_reader": {
      $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/qr_reader' as const, hash: url?.hash })
    },
    "rental": {
      _groupId: (groupId: string | number) => ({
        "lockers": {
          _lockerId: (lockerId: string | number) => ({
            $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/rental/[groupId]/lockers/[lockerId]' as const, query: { groupId, lockerId }, hash: url?.hash })
          })
        }
      }),
      $url: (url?: { query?: OptionalQuery2, hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f/rental' as const, query: url?.query, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/7ac6dd9d-43f8-4f2b-b632-9d18c6fe239f' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

export type PagesPath = typeof pagesPath
