import { useRouter } from 'next/router';
import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
  VFC,
} from 'react';
import { pagesPath } from '~/utils/$path';
import { UrlObject } from 'url';
import { LinearProgress, Typography } from '@mui/material';
import { User, UserQuery, useUserQuery } from '~/graphql/urql.generated';
import { LOCAL_STORAGE_ACCESS_TOKEN } from '~/utils/urql';
import * as Sentry from '@sentry/nextjs';

type AuthContextProps = {
  token: string | null;
  user: User | null | undefined; //apiレスポンス待ちはundefined
  setAuth: (token: string) => void;
  checkUser: () => Promise<void>;
  logout: () => void;
  gotoAfterLoginPage: () => void;
};

type AuthProviderProps = {
  children: ReactNode;
};

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

const AuthProvider: VFC<AuthProviderProps> = (props: AuthProviderProps) => {
  const initalToken =
    typeof window !== 'undefined'
      ? localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) || null
      : null;
  const [token, setToken] = useState<AuthContextProps['token']>(initalToken);
  const [userQueryResult, updateUserQuery] = useUserQuery();
  const [user, setUser] = useState<UserQuery['user'] | null | undefined>(
    undefined,
  );

  const noLoginPageList = [
    pagesPath.$7ac6dd9d_43f8_4f2b_b632_9d18c6fe239f.login.$url().pathname,
  ];

  const router = useRouter();

  const gotoLoginPage = async () => {
    if (noLoginPageList.find((one) => one === router.pathname)) {
      return;
    }
    await router.push(
      pagesPath.$7ac6dd9d_43f8_4f2b_b632_9d18c6fe239f.login.$url(),
    );
  };

  const setAuth = (token: string) => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, token);
    }
    setToken(token);
    updateUserQuery();
  };

  const gotoAfterLoginPage = async () => {
    let userHomePage: UrlObject | string =
      pagesPath.$7ac6dd9d_43f8_4f2b_b632_9d18c6fe239f.$url();
    await router.push(userHomePage);
  };

  const logout = async () => {
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
    setUser(null);
    setToken(null);
    await gotoLoginPage();
  };

  useEffect(() => {
    console.log('checkAuth useEffect');

    const checkAuth = async () => {
      console.log('checkAuth', userQueryResult);
      if (token === null) {
        console.log('checkAuth gotoLoginPage1');
        await gotoLoginPage();
      } else if (
        userQueryResult.error &&
        userQueryResult.error.graphQLErrors.find(
          (e) => e.extensions.code === 'UNAUTHENTICATED',
        )
      ) {
        console.log('checkAuth gotoLoginPage2');
        await gotoLoginPage();
      } else if (!userQueryResult.fetching) {
        updateUserQuery();
      }
    };

    checkAuth();
    const timer = setInterval(checkAuth, 10 * 60 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (token !== null) updateUserQuery();
  }, [token]);

  useEffect(() => {
    if (!userQueryResult.fetching) {
      if (userQueryResult.data && userQueryResult.data.user) {
        const user = userQueryResult.data.user;
        setUser(user);
        Sentry.setUser({
          id: user.id,
          email: user.email,
          username: user.name,
        });
      } else {
        Sentry.setUser(null);
        setUser(null);
      }
    }
  }, [userQueryResult]);

  const checkUser = async () => {
    await updateUserQuery();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        setAuth,
        logout,
        checkUser,
        gotoAfterLoginPage,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
