import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AccountOnLockerReservation = {
  __typename?: 'AccountOnLockerReservation';
  member: User;
  role: LockerMemberRole;
};

export enum AccountRole {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  User = 'USER'
}

export type AlreadyReadNotification = {
  readUntilDate: Scalars['String'];
};

export type ApproveLockerReservationInput = {
  id: Scalars['ID'];
};

export type AuthCredential = {
  __typename?: 'AuthCredential';
  access_token: Scalars['String'];
};

export type ChangeMemberModeInput = {
  id: Scalars['ID'];
  memberMode: LockerReservationMemberMode;
};

export type CreateLockerGroupInput = {
  name: Scalars['String'];
};

export type CreateTenantInput = {
  name: Scalars['String'];
};

export type EndOfUseLockerReservationInput = {
  forAdmin?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type HealthCheck = {
  __typename?: 'HealthCheck';
  userCount: Scalars['Float'];
};

export type Input = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  role: AccountRole;
};

export type InviteMemberToReservationInput = {
  id: Scalars['ID'];
  message: Scalars['String'];
  role: LockerMemberRole;
  userIds: Array<Scalars['String']>;
};

export type Locker = {
  __typename?: 'Locker';
  id: Scalars['ID'];
  lockerGroupId: Scalars['String'];
  mode: LockerMode;
  name: Scalars['String'];
  obnizId: Scalars['String'];
  rentalItemName?: Maybe<Scalars['String']>;
  rentalItemPeriod?: Maybe<Scalars['Float']>;
  status: LockerStatus;
};

export type LockerGroup = {
  __typename?: 'LockerGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum LockerMemberRole {
  Member = 'MEMBER',
  OneTimeUnlock = 'ONE_TIME_UNLOCK',
  Owner = 'OWNER'
}

export enum LockerMode {
  Rental = 'RENTAL',
  Season = 'SEASON',
  Temporary = 'TEMPORARY'
}

export type LockerReservation = {
  __typename?: 'LockerReservation';
  accounts: Array<AccountOnLockerReservation>;
  applicationDate: Scalars['String'];
  applicationReason: Scalars['String'];
  approvalStatus: LockerReservationApprovalStatus;
  endDate: Scalars['String'];
  id: Scalars['ID'];
  locker: Locker;
  memberMode: LockerReservationMemberMode;
  myRole: LockerMemberRole;
  owner: User;
  rejectReason?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  useStatus: LockerReservationUseStatus;
};

export enum LockerReservationApprovalStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum LockerReservationMemberMode {
  All = 'ALL',
  Spec = 'SPEC'
}

export enum LockerReservationUseStatus {
  EndOfUse = 'END_OF_USE',
  InUse = 'IN_USE',
  Unused = 'UNUSED'
}

export enum LockerStatus {
  Free = 'FREE',
  InUse = 'IN_USE',
  UnderMaintenance = 'UNDER_MAINTENANCE'
}

export enum LockerUpsertStatus {
  Free = 'FREE',
  UnderMaintenance = 'UNDER_MAINTENANCE'
}

export type Log = {
  __typename?: 'Log';
  action: LogType;
  createdAt: Scalars['String'];
  data: Scalars['String'];
  id: Scalars['ID'];
};

export type LogEdges = {
  __typename?: 'LogEdges';
  cursor: Scalars['String'];
  node: Log;
};

export type LogOutputPagination = {
  __typename?: 'LogOutputPagination';
  edges?: Maybe<Array<LogEdges>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type LogSearchInput = {
  endDate?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  lockerGroupId?: InputMaybe<Scalars['String']>;
  lockerId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export enum LogType {
  LockerForceUnlock = 'LOCKER_FORCE_UNLOCK',
  LockerStartUsing = 'LOCKER_START_USING',
  LockerUnlock = 'LOCKER_UNLOCK',
  LockerUnlockByMember = 'LOCKER_UNLOCK_BY_MEMBER',
  LockerUnlockByOneTimeUnlock = 'LOCKER_UNLOCK_BY_ONE_TIME_UNLOCK',
  LockerUnlockByOwner = 'LOCKER_UNLOCK_BY_OWNER',
  ReservationApplicationOfUse = 'RESERVATION_APPLICATION_OF_USE',
  ReservationApproved = 'RESERVATION_APPROVED',
  ReservationEndOfUse = 'RESERVATION_END_OF_USE',
  ReservationForceEndOfUse = 'RESERVATION_FORCE_END_OF_USE',
  ReservationReject = 'RESERVATION_REJECT',
  UpdateLocker = 'UPDATE_LOCKER'
}

export type LoginCredential = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  alreadyReadNotification: Scalars['Boolean'];
  approveReservation: Scalars['Boolean'];
  changeReservationMemberMode: Scalars['Boolean'];
  createLockerGroup: LockerGroup;
  createTenant: Tenant;
  deleteUser: Scalars['Boolean'];
  endOfUseLockerReservation: Scalars['Boolean'];
  importUsers: Array<User>;
  inviteMemberToReservation: Scalars['Boolean'];
  lockerIsOnline: Scalars['Boolean'];
  lockerObnizWakeup: Locker;
  login: AuthCredential;
  rejectReservation: Scalars['Boolean'];
  removeMemberFromReservation: Scalars['Boolean'];
  reserveLocker: LockerReservation;
  unlockLocker: Scalars['Boolean'];
  updateUser: User;
  upsertLocker: Locker;
};


export type MutationAlreadyReadNotificationArgs = {
  args: AlreadyReadNotification;
};


export type MutationApproveReservationArgs = {
  args: ApproveLockerReservationInput;
};


export type MutationChangeReservationMemberModeArgs = {
  args: ChangeMemberModeInput;
};


export type MutationCreateLockerGroupArgs = {
  group: CreateLockerGroupInput;
};


export type MutationCreateTenantArgs = {
  tenant: CreateTenantInput;
};


export type MutationDeleteUserArgs = {
  user: UserDeleteInput;
};


export type MutationEndOfUseLockerReservationArgs = {
  args: EndOfUseLockerReservationInput;
};


export type MutationImportUsersArgs = {
  users: Array<Input>;
};


export type MutationInviteMemberToReservationArgs = {
  args: InviteMemberToReservationInput;
};


export type MutationLockerIsOnlineArgs = {
  args: LockerIsOnlineInput;
};


export type MutationLockerObnizWakeupArgs = {
  args: WakeupObnizLocker;
};


export type MutationLoginArgs = {
  credential: LoginCredential;
};


export type MutationRejectReservationArgs = {
  args: RejectLockerReservationInput;
};


export type MutationRemoveMemberFromReservationArgs = {
  args: RemoveMemberFormReservationInput;
};


export type MutationReserveLockerArgs = {
  args: ReserveLockerInput;
};


export type MutationUnlockLockerArgs = {
  args: UnlockLockerInput;
};


export type MutationUpdateUserArgs = {
  user: UserUpdateInput;
};


export type MutationUpsertLockerArgs = {
  locker: UpsertLocker;
};

export type Notification = {
  __typename?: 'Notification';
  accountId: Scalars['ID'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  level: NotificationLevel;
  message?: Maybe<Scalars['String']>;
  metadata: Scalars['String'];
  readAt?: Maybe<Scalars['String']>;
  type: NotificationType;
};

export type NotificationEdges = {
  __typename?: 'NotificationEdges';
  cursor: Scalars['String'];
  node: Notification;
};

export enum NotificationLevel {
  Info = 'INFO',
  Warning = 'WARNING'
}

export type NotificationOutputPagination = {
  __typename?: 'NotificationOutputPagination';
  edges?: Maybe<Array<NotificationEdges>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export enum NotificationType {
  AccountCreated = 'ACCOUNT_CREATED',
  LockerOpened = 'LOCKER_OPENED',
  ReservationApproved = 'RESERVATION_APPROVED',
  ReservationEndOfUse = 'RESERVATION_END_OF_USE',
  ReservationExpired = 'RESERVATION_EXPIRED',
  ReservationExpiringSoon = 'RESERVATION_EXPIRING_SOON',
  ReservationMemberInvited = 'RESERVATION_MEMBER_INVITED',
  ReservationMemberInviteKicked = 'RESERVATION_MEMBER_INVITE_KICKED',
  ReservationOneTimeInvited = 'RESERVATION_ONE_TIME_INVITED',
  ReservationOneTimeInviteKicked = 'RESERVATION_ONE_TIME_INVITE_KICKED',
  ReservationRejected = 'RESERVATION_REJECTED',
  ReservationRentalExpired = 'RESERVATION_RENTAL_EXPIRED',
  ReservationRentalExpiringSoon = 'RESERVATION_RENTAL_EXPIRING_SOON'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  healthCheck: HealthCheck;
  lockerCanUse: Scalars['Boolean'];
  notifications: NotificationOutputPagination;
  searchLockerGroups: Array<LockerGroup>;
  searchLockers: Array<Locker>;
  searchLogs: LogOutputPagination;
  searchReservedLockers: Array<LockerReservation>;
  searchUsers: UserOutputPagination;
  startUseTransaction: Array<Locker>;
  user: User;
};


export type QueryLockerCanUseArgs = {
  args: LockerInUsedInput;
};


export type QueryNotificationsArgs = {
  args: SearchNotification;
};


export type QuerySearchLockerGroupsArgs = {
  args: SearchLockerGroupInput;
};


export type QuerySearchLockersArgs = {
  args: SearchLockerInput;
};


export type QuerySearchLogsArgs = {
  args: LogSearchInput;
};


export type QuerySearchReservedLockersArgs = {
  args: SearchReservedLockerInput;
};


export type QuerySearchUsersArgs = {
  args: UserSearchInput;
};

export type RejectLockerReservationInput = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};

export type RemoveMemberFormReservationInput = {
  id: Scalars['ID'];
  userId: Scalars['String'];
};

export type SearchLockerGroupInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type SearchLockerInput = {
  id?: InputMaybe<Scalars['String']>;
  lockerGroupId?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<LockerMode>;
  name?: InputMaybe<Scalars['String']>;
};

export type SearchNotification = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type SearchReservedLockerInput = {
  approvalStatus?: InputMaybe<LockerReservationApprovalStatus>;
  forAdmin?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  lockerId?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['String']>;
  useStatus?: InputMaybe<LockerReservationUseStatus>;
};

export type Tenant = {
  __typename?: 'Tenant';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpsertLocker = {
  lockerGroupId: Scalars['String'];
  mode: LockerMode;
  name: Scalars['String'];
  obnizId: Scalars['String'];
  rentalItemName?: InputMaybe<Scalars['String']>;
  rentalItemPeriod?: InputMaybe<Scalars['Int']>;
  status: LockerUpsertStatus;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  role: AccountRole;
};

export type UserDeleteInput = {
  id: Scalars['ID'];
};

export type UserEdges = {
  __typename?: 'UserEdges';
  cursor: Scalars['String'];
  node: User;
};

export type UserOutputPagination = {
  __typename?: 'UserOutputPagination';
  edges?: Maybe<Array<UserEdges>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UserSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  keyword?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<UserSearchOrder>;
};

export enum UserSearchOrder {
  CreatedAt = 'createdAt',
  Name = 'name'
}

export type UserUpdateInput = {
  id: Scalars['ID'];
  role: AccountRole;
};

export type WakeupObnizLocker = {
  obnizId: Scalars['String'];
};

export type LockerInUsedInput = {
  id?: InputMaybe<Scalars['ID']>;
  obnizId?: InputMaybe<Scalars['ID']>;
};

export type LockerIsOnlineInput = {
  id: Scalars['ID'];
};

export type ReserveLockerInput = {
  applicationReason: Scalars['String'];
  endDate: Scalars['String'];
  lockerId: Scalars['String'];
  startDate: Scalars['String'];
};

export type UnlockLockerInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type LoginMutationVariables = Exact<{
  credential: LoginCredential;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthCredential', access_token: string } };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', email: string, id: string, name: string, role: AccountRole } };

export type ImportUsersMutationVariables = Exact<{
  users: Array<Input> | Input;
}>;


export type ImportUsersMutation = { __typename?: 'Mutation', importUsers: Array<{ __typename?: 'User', email: string, id: string, name: string, role: AccountRole }> };

export type SearchLockersQueryVariables = Exact<{
  args: SearchLockerInput;
}>;


export type SearchLockersQuery = { __typename?: 'Query', searchLockers: Array<{ __typename?: 'Locker', id: string, lockerGroupId: string, mode: LockerMode, name: string, rentalItemName?: string | null, rentalItemPeriod?: number | null, status: LockerStatus, obnizId: string }> };

export type SearchLockerGroupsQueryVariables = Exact<{
  args: SearchLockerGroupInput;
}>;


export type SearchLockerGroupsQuery = { __typename?: 'Query', searchLockerGroups: Array<{ __typename?: 'LockerGroup', id: string, name: string }> };

export type UpsertLockerMutationVariables = Exact<{
  locker: UpsertLocker;
}>;


export type UpsertLockerMutation = { __typename?: 'Mutation', upsertLocker: { __typename?: 'Locker', lockerGroupId: string, mode: LockerMode, name: string, obnizId: string, rentalItemName?: string | null, rentalItemPeriod?: number | null, status: LockerStatus } };

export type SearchReservedLockersQueryVariables = Exact<{
  args: SearchReservedLockerInput;
}>;


export type SearchReservedLockersQuery = { __typename?: 'Query', searchReservedLockers: Array<{ __typename?: 'LockerReservation', applicationDate: string, applicationReason: string, approvalStatus: LockerReservationApprovalStatus, endDate: string, id: string, myRole: LockerMemberRole, rejectReason?: string | null, startDate: string, useStatus: LockerReservationUseStatus, memberMode: LockerReservationMemberMode, accounts: Array<{ __typename?: 'AccountOnLockerReservation', role: LockerMemberRole, member: { __typename?: 'User', email: string, id: string, name: string, role: AccountRole } }>, locker: { __typename?: 'Locker', id: string, lockerGroupId: string, mode: LockerMode, name: string, rentalItemName?: string | null, rentalItemPeriod?: number | null, status: LockerStatus, obnizId: string }, owner: { __typename?: 'User', email: string, id: string, name: string, role: AccountRole } }> };

export type SearchReservedLockersForAdminQueryVariables = Exact<{
  args: SearchReservedLockerInput;
}>;


export type SearchReservedLockersForAdminQuery = { __typename?: 'Query', searchReservedLockers: Array<{ __typename?: 'LockerReservation', applicationDate: string, applicationReason: string, approvalStatus: LockerReservationApprovalStatus, endDate: string, id: string, rejectReason?: string | null, startDate: string, useStatus: LockerReservationUseStatus, memberMode: LockerReservationMemberMode, accounts: Array<{ __typename?: 'AccountOnLockerReservation', role: LockerMemberRole, member: { __typename?: 'User', email: string, id: string, name: string, role: AccountRole } }>, locker: { __typename?: 'Locker', id: string, lockerGroupId: string, mode: LockerMode, name: string, rentalItemName?: string | null, rentalItemPeriod?: number | null, status: LockerStatus, obnizId: string }, owner: { __typename?: 'User', email: string, id: string, name: string, role: AccountRole } }> };

export type UnlockLockerMutationVariables = Exact<{
  args: UnlockLockerInput;
}>;


export type UnlockLockerMutation = { __typename?: 'Mutation', unlockLocker: boolean };

export type LockerIsOnlineMutationVariables = Exact<{
  args: LockerIsOnlineInput;
}>;


export type LockerIsOnlineMutation = { __typename?: 'Mutation', lockerIsOnline: boolean };

export type CreateLockerGroupMutationVariables = Exact<{
  group: CreateLockerGroupInput;
}>;


export type CreateLockerGroupMutation = { __typename?: 'Mutation', createLockerGroup: { __typename?: 'LockerGroup', id: string, name: string } };

export type SearchLogsQueryVariables = Exact<{
  args: LogSearchInput;
}>;


export type SearchLogsQuery = { __typename?: 'Query', searchLogs: { __typename?: 'LogOutputPagination', totalCount: number, edges?: Array<{ __typename?: 'LogEdges', cursor: string, node: { __typename?: 'Log', action: LogType, createdAt: string, data: string, id: string } }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } };

export type NotificationsQueryVariables = Exact<{
  args: SearchNotification;
}>;


export type NotificationsQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationOutputPagination', totalCount: number, edges?: Array<{ __typename?: 'NotificationEdges', cursor: string, node: { __typename?: 'Notification', accountId: string, createdAt: string, id: string, level: NotificationLevel, metadata: string, message?: string | null, readAt?: string | null, type: NotificationType } }> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } };

export type AlreadyReadNotificationMutationVariables = Exact<{
  args: AlreadyReadNotification;
}>;


export type AlreadyReadNotificationMutation = { __typename?: 'Mutation', alreadyReadNotification: boolean };

export type ReserveLockerMutationVariables = Exact<{
  args: ReserveLockerInput;
}>;


export type ReserveLockerMutation = { __typename?: 'Mutation', reserveLocker: { __typename?: 'LockerReservation', applicationDate: string, applicationReason: string, approvalStatus: LockerReservationApprovalStatus, endDate: string, id: string, rejectReason?: string | null, startDate: string, useStatus: LockerReservationUseStatus, memberMode: LockerReservationMemberMode, locker: { __typename?: 'Locker', id: string, lockerGroupId: string, mode: LockerMode, name: string, rentalItemName?: string | null, rentalItemPeriod?: number | null, status: LockerStatus, obnizId: string } } };

export type EndOfUseLockerReservationMutationVariables = Exact<{
  args: EndOfUseLockerReservationInput;
}>;


export type EndOfUseLockerReservationMutation = { __typename?: 'Mutation', endOfUseLockerReservation: boolean };

export type InviteMemberToReservationMutationVariables = Exact<{
  args: InviteMemberToReservationInput;
}>;


export type InviteMemberToReservationMutation = { __typename?: 'Mutation', inviteMemberToReservation: boolean };

export type RemoveMemberFromReservationMutationVariables = Exact<{
  args: RemoveMemberFormReservationInput;
}>;


export type RemoveMemberFromReservationMutation = { __typename?: 'Mutation', removeMemberFromReservation: boolean };

export type ApproveReservationMutationVariables = Exact<{
  args: ApproveLockerReservationInput;
}>;


export type ApproveReservationMutation = { __typename?: 'Mutation', approveReservation: boolean };

export type RejectReservationMutationVariables = Exact<{
  args: RejectLockerReservationInput;
}>;


export type RejectReservationMutation = { __typename?: 'Mutation', rejectReservation: boolean };

export type ChangeReservationMemberModeMutationVariables = Exact<{
  args: ChangeMemberModeInput;
}>;


export type ChangeReservationMemberModeMutation = { __typename?: 'Mutation', changeReservationMemberMode: boolean };

export type SearchUsersQueryVariables = Exact<{
  args: UserSearchInput;
}>;


export type SearchUsersQuery = { __typename?: 'Query', searchUsers: { __typename?: 'UserOutputPagination', totalCount: number, edges?: Array<{ __typename?: 'UserEdges', cursor: string, node: { __typename?: 'User', email: string, id: string, name: string, role: AccountRole } }> | null, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null } } };

export type DeleteUserMutationVariables = Exact<{
  user: UserDeleteInput;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type UpdateUserMutationVariables = Exact<{
  user: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', email: string, id: string, name: string, role: AccountRole } };


export const LoginDocument = gql`
    mutation login($credential: LoginCredential!) {
  login(credential: $credential) {
    access_token
  }
}
    `;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
};
export const UserDocument = gql`
    query User {
  user {
    email
    id
    name
    role
  }
}
    `;

export function useUserQuery(options?: Omit<Urql.UseQueryArgs<UserQueryVariables>, 'query'>) {
  return Urql.useQuery<UserQuery>({ query: UserDocument, ...options });
};
export const ImportUsersDocument = gql`
    mutation ImportUsers($users: [Input!]!) {
  importUsers(users: $users) {
    email
    id
    name
    role
  }
}
    `;

export function useImportUsersMutation() {
  return Urql.useMutation<ImportUsersMutation, ImportUsersMutationVariables>(ImportUsersDocument);
};
export const SearchLockersDocument = gql`
    query SearchLockers($args: SearchLockerInput!) {
  searchLockers(args: $args) {
    id
    lockerGroupId
    mode
    name
    rentalItemName
    rentalItemPeriod
    status
    obnizId
  }
}
    `;

export function useSearchLockersQuery(options: Omit<Urql.UseQueryArgs<SearchLockersQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchLockersQuery>({ query: SearchLockersDocument, ...options });
};
export const SearchLockerGroupsDocument = gql`
    query SearchLockerGroups($args: SearchLockerGroupInput!) {
  searchLockerGroups(args: $args) {
    id
    name
  }
}
    `;

export function useSearchLockerGroupsQuery(options: Omit<Urql.UseQueryArgs<SearchLockerGroupsQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchLockerGroupsQuery>({ query: SearchLockerGroupsDocument, ...options });
};
export const UpsertLockerDocument = gql`
    mutation UpsertLocker($locker: UpsertLocker!) {
  upsertLocker(locker: $locker) {
    lockerGroupId
    mode
    name
    obnizId
    rentalItemName
    rentalItemPeriod
    status
  }
}
    `;

export function useUpsertLockerMutation() {
  return Urql.useMutation<UpsertLockerMutation, UpsertLockerMutationVariables>(UpsertLockerDocument);
};
export const SearchReservedLockersDocument = gql`
    query SearchReservedLockers($args: SearchReservedLockerInput!) {
  searchReservedLockers(args: $args) {
    accounts {
      member {
        email
        id
        name
        role
      }
      role
    }
    applicationDate
    applicationReason
    approvalStatus
    endDate
    id
    locker {
      id
      lockerGroupId
      mode
      name
      rentalItemName
      rentalItemPeriod
      status
      obnizId
    }
    myRole
    owner {
      email
      id
      name
      role
    }
    rejectReason
    startDate
    useStatus
    memberMode
  }
}
    `;

export function useSearchReservedLockersQuery(options: Omit<Urql.UseQueryArgs<SearchReservedLockersQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchReservedLockersQuery>({ query: SearchReservedLockersDocument, ...options });
};
export const SearchReservedLockersForAdminDocument = gql`
    query SearchReservedLockersForAdmin($args: SearchReservedLockerInput!) {
  searchReservedLockers(args: $args) {
    accounts {
      member {
        email
        id
        name
        role
      }
      role
    }
    applicationDate
    applicationReason
    approvalStatus
    endDate
    id
    locker {
      id
      lockerGroupId
      mode
      name
      rentalItemName
      rentalItemPeriod
      status
      obnizId
    }
    owner {
      email
      id
      name
      role
    }
    rejectReason
    startDate
    useStatus
    memberMode
  }
}
    `;

export function useSearchReservedLockersForAdminQuery(options: Omit<Urql.UseQueryArgs<SearchReservedLockersForAdminQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchReservedLockersForAdminQuery>({ query: SearchReservedLockersForAdminDocument, ...options });
};
export const UnlockLockerDocument = gql`
    mutation UnlockLocker($args: unlockLockerInput!) {
  unlockLocker(args: $args)
}
    `;

export function useUnlockLockerMutation() {
  return Urql.useMutation<UnlockLockerMutation, UnlockLockerMutationVariables>(UnlockLockerDocument);
};
export const LockerIsOnlineDocument = gql`
    mutation LockerIsOnline($args: lockerIsOnlineInput!) {
  lockerIsOnline(args: $args)
}
    `;

export function useLockerIsOnlineMutation() {
  return Urql.useMutation<LockerIsOnlineMutation, LockerIsOnlineMutationVariables>(LockerIsOnlineDocument);
};
export const CreateLockerGroupDocument = gql`
    mutation CreateLockerGroup($group: CreateLockerGroupInput!) {
  createLockerGroup(group: $group) {
    id
    name
  }
}
    `;

export function useCreateLockerGroupMutation() {
  return Urql.useMutation<CreateLockerGroupMutation, CreateLockerGroupMutationVariables>(CreateLockerGroupDocument);
};
export const SearchLogsDocument = gql`
    query SearchLogs($args: LogSearchInput!) {
  searchLogs(args: $args) {
    edges {
      cursor
      node {
        action
        createdAt
        data
        id
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    `;

export function useSearchLogsQuery(options: Omit<Urql.UseQueryArgs<SearchLogsQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchLogsQuery>({ query: SearchLogsDocument, ...options });
};
export const NotificationsDocument = gql`
    query Notifications($args: SearchNotification!) {
  notifications(args: $args) {
    edges {
      cursor
      node {
        accountId
        createdAt
        id
        level
        metadata
        message
        readAt
        type
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    `;

export function useNotificationsQuery(options: Omit<Urql.UseQueryArgs<NotificationsQueryVariables>, 'query'>) {
  return Urql.useQuery<NotificationsQuery>({ query: NotificationsDocument, ...options });
};
export const AlreadyReadNotificationDocument = gql`
    mutation AlreadyReadNotification($args: AlreadyReadNotification!) {
  alreadyReadNotification(args: $args)
}
    `;

export function useAlreadyReadNotificationMutation() {
  return Urql.useMutation<AlreadyReadNotificationMutation, AlreadyReadNotificationMutationVariables>(AlreadyReadNotificationDocument);
};
export const ReserveLockerDocument = gql`
    mutation ReserveLocker($args: reserveLockerInput!) {
  reserveLocker(args: $args) {
    applicationDate
    applicationReason
    approvalStatus
    endDate
    id
    locker {
      id
      lockerGroupId
      mode
      name
      rentalItemName
      rentalItemPeriod
      status
      obnizId
    }
    rejectReason
    startDate
    useStatus
    memberMode
  }
}
    `;

export function useReserveLockerMutation() {
  return Urql.useMutation<ReserveLockerMutation, ReserveLockerMutationVariables>(ReserveLockerDocument);
};
export const EndOfUseLockerReservationDocument = gql`
    mutation EndOfUseLockerReservation($args: EndOfUseLockerReservationInput!) {
  endOfUseLockerReservation(args: $args)
}
    `;

export function useEndOfUseLockerReservationMutation() {
  return Urql.useMutation<EndOfUseLockerReservationMutation, EndOfUseLockerReservationMutationVariables>(EndOfUseLockerReservationDocument);
};
export const InviteMemberToReservationDocument = gql`
    mutation InviteMemberToReservation($args: InviteMemberToReservationInput!) {
  inviteMemberToReservation(args: $args)
}
    `;

export function useInviteMemberToReservationMutation() {
  return Urql.useMutation<InviteMemberToReservationMutation, InviteMemberToReservationMutationVariables>(InviteMemberToReservationDocument);
};
export const RemoveMemberFromReservationDocument = gql`
    mutation RemoveMemberFromReservation($args: RemoveMemberFormReservationInput!) {
  removeMemberFromReservation(args: $args)
}
    `;

export function useRemoveMemberFromReservationMutation() {
  return Urql.useMutation<RemoveMemberFromReservationMutation, RemoveMemberFromReservationMutationVariables>(RemoveMemberFromReservationDocument);
};
export const ApproveReservationDocument = gql`
    mutation ApproveReservation($args: ApproveLockerReservationInput!) {
  approveReservation(args: $args)
}
    `;

export function useApproveReservationMutation() {
  return Urql.useMutation<ApproveReservationMutation, ApproveReservationMutationVariables>(ApproveReservationDocument);
};
export const RejectReservationDocument = gql`
    mutation RejectReservation($args: RejectLockerReservationInput!) {
  rejectReservation(args: $args)
}
    `;

export function useRejectReservationMutation() {
  return Urql.useMutation<RejectReservationMutation, RejectReservationMutationVariables>(RejectReservationDocument);
};
export const ChangeReservationMemberModeDocument = gql`
    mutation ChangeReservationMemberMode($args: ChangeMemberModeInput!) {
  changeReservationMemberMode(args: $args)
}
    `;

export function useChangeReservationMemberModeMutation() {
  return Urql.useMutation<ChangeReservationMemberModeMutation, ChangeReservationMemberModeMutationVariables>(ChangeReservationMemberModeDocument);
};
export const SearchUsersDocument = gql`
    query SearchUsers($args: UserSearchInput!) {
  searchUsers(args: $args) {
    edges {
      cursor
      node {
        email
        id
        name
        role
      }
    }
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    totalCount
  }
}
    `;

export function useSearchUsersQuery(options: Omit<Urql.UseQueryArgs<SearchUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<SearchUsersQuery>({ query: SearchUsersDocument, ...options });
};
export const DeleteUserDocument = gql`
    mutation DeleteUser($user: UserDeleteInput!) {
  deleteUser(user: $user)
}
    `;

export function useDeleteUserMutation() {
  return Urql.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument);
};
export const UpdateUserDocument = gql`
    mutation UpdateUser($user: UserUpdateInput!) {
  updateUser(user: $user) {
    email
    id
    name
    role
  }
}
    `;

export function useUpdateUserMutation() {
  return Urql.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument);
};