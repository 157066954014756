import * as nextUrql from 'next-urql';
import { AuthConfig, authExchange } from '@urql/exchange-auth';
import { makeOperation } from 'urql';
import { createClient, dedupExchange, fetchExchange, cacheExchange, errorExchange } from 'urql';
import * as Sentry from "@sentry/nextjs";


type AuthState = {
  token: string;
};

export const LOCAL_STORAGE_ACCESS_TOKEN = 'access_token';

export const withUrqlClient = nextUrql.withUrqlClient((_ssrExchange, ctx) => ({
  // ...add your Client options here
  url: process.env.NEXT_PUBLIC_API_URL_BASE ?? '/graphql',
  fetchOptions: () => {
    if (typeof window !== 'undefined') {
      const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
      return token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    }
    return {};
  },
  requestPolicy: 'cache-and-network', // FIXME: ホントは適切なところはキャッシュしたい
  exchanges: [
    dedupExchange,
    cacheExchange,
    errorExchange({

      onError(error) {
        console.error(error);
        if(error.networkError){
          Sentry.captureException(error.networkError);
        }
      },
    }),
    fetchExchange,
  ],
}));
